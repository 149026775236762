import getBaseUrl from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/api/getBaseUrl';
import { CONFIG_ID } from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-retaliation-complaint/config';

const getContentConfiguration = async (context, {
    oneLni,
}) => {
    const baseUrl = getBaseUrl(oneLni);
    const endpoint = `${baseUrl}/api/content/id/${CONFIG_ID}`;
    try {
        const response = await fetch(endpoint, {
            method: 'GET',
        });
        const result = await response.text();
        return JSON.parse(result).contentlets[0].configuration;
    } catch (error) {
        return context.dispatch(`handleError`, {
            step: 'initialize',
            error,
        });
    }
};

export default getContentConfiguration;