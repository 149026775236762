export const text = {
    // eslint-disable-next-line max-len
    'generalError.initialize': 'There was a problem loading this form. Please refresh the page. If the error continues CONTACT.',
    'generalError.submit': 'There was a problem submitting your form. Please refresh the page and try again. If the error continues CONTACT.',
    'attestation.labelText': 'I hereby affirm and attest that I have personal knowledge that the situation occurred and my name on this form legally constitutes my signature.',
    'contactAddress.labelText': 'Address',
    'contactCounty.labelText': 'County',
    'contactEmail.labelText': 'Email',
    'contactFieldset.helperText': 'Please provide at least one method of contact.',
    'contactFieldset.legend': 'Contact Information',
    'contactName.labelText': 'Name',
    'contactPhone.labelText': 'Phone',
    'contactPhoneType.labelText': 'Is this a cell phone number?',
    'contactPhoneType.options.false': 'No',
    'contactPhoneType.options.true': 'Yes',
    'contactPreference.labelText': 'Which contact method and time of day do you prefer?',
    'contactTextPermission.helperText': 'Your cell provider may charge for message or data.',
    'contactTextPermission.labelText': 'Can we text you at this number?',
    'contactTextPermission.options.false': 'No',
    'contactTextPermission.options.true': 'Yes',
    'contactTitle.labelText': 'Title',
    'contactTitle.options.Mr.': 'Mr.',
    'contactTitle.options.Mrs.': 'Mrs.',
    'contactTitle.options.Ms.': 'Ms.',
    'contactTitle.options.Mx.': 'Mx.',
    'contactTitle.options.other': 'Other/None',
    'dateHired.labelText': 'Date Hired',
    'dateResigned.labelText': 'When did you resign?',
    'dateTerminated.labelText': 'When were you terminated?',
    'employerAddress.labelText': 'Employer Address',
    employerInformation: 'Employer Information',
    'employerName.labelText': 'Employer Name',
    'employerPhone.labelText': 'Employer Phone',
    'hasContactedOtherAgency.labelText': 'Have you filed your complaint with another agency?',
    'hasContactedOtherAgency.options.false': 'No',
    'hasContactedOtherAgency.options.true': 'Yes',
    'isResigned.labelText': 'Did you resign from your job?',
    'isResigned.options.false': 'No',
    'isResigned.options.true': 'Yes',
    'isTerminated.labelText': 'Was your job terminated?',
    'isTerminated.options.false': 'No',
    'isTerminated.options.true': 'Yes',
    jobInformation: 'Job Information',
    'jobTitle.labelText': 'Job Title',
    legalInformationLabel: 'Information about your rights',
    'otherAgency.labelText': 'Which agency have you contacted?',
    'otherAgencyContactDate.labelText': 'When did you contact them?',
    'otherAgencyResults.labelText': 'What were the results of the complaint?',
    requiredFieldsMessage: 'Fields marked with an asterix(*) are required.',
    'retaliationDate.labelText': 'When did the retaliation occur?',
    'retaliationDescription.helperText': 'We will gather more information when we contact you.',
    'retaliationDescription.labelText': 'Describe how your were retaliated against.',
    'retaliationReason.labelText': 'In your opinion, why did the employer take this action?',
    legalInformation: '',
    successMessageTitle: 'Success',
    successMessage: '',
    errorMessageTitle: 'There was an error',
};